.background-image {
    background-image: url("../assets/image/bannerprincipal.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.form-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
