.searcher-container {
    background-color: #0240A3;
}

.card-list-container {
    height: 68vh;
}

.table-row-boderless {
    border-top: none;
}

.table-row-boderless :first-child {
    border-left: none;
}

.table-row-boderless :last-child {
    border-right: none;
}
.table-row-boderless-bottom :first-child {
    border-left: none;
}

.table-row-boderless-bottom :last-child {
    border-right: none;
}

.table-row-boderless-bottom {
    border-bottom: none;
}

.table-col-boderless-bottom{
    border-right: none !important;
}
