.backgorund-logo-container {
    background-color: #F4F4F4;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.backgorund-logo-container p {
    color: #646464;
}

.backgorund-logo-container p:first-child {
    font-size: 22px;
}

.copyright-text {
    color: #2C3C4A;
    font: -webkit-small-control;
}
