.card-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #2F4C580F;
    border-radius: 8px;
    opacity: 1;
}

.th-border-bottom {
    border-bottom-width: 1px;
    border-color: #A2A2A2;
}

.tr-border-right {
    border-right-width: 1px;
    border-color: #A2A2A2;    
}

.rep-text {
    color: #0240A3;
}

.points {
    background-color: #0240A3;
    padding: 5px;
    color: white;
    border-radius: 5px;
}

.pay-button {
    background-color: #0240A3 !important;
    color: #FFFFFF !important;
    border-radius: 9px !important;
    border: 0;
    font-weight: 400 !important;
    margin: 0 auto !important;
    width: 100%;
}
@media (max-width: 920px) {
    .pay-button {
        width: 180px !important;
    }
}
.hover-border {
    border-color: 1px solid #2EC3E5;
    border-width: 3px;
    border-style: solid;
}

.border-info {
    border-width: 3px !important;
    border-style: solid !important;
}
