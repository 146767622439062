select {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #929292;
  border-radius: 5px;
  opacity: 1;
  font: normal normal normal 18px/25px;
  letter-spacing: 0px;
  color: #505050;
  opacity: 1;
}

option {
  font: normal normal normal 18px/25px;
  letter-spacing: 0px;
  color: #505050;
  opacity: 1;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.hp-10 {
  height: 10vh;
}

.hp-20 {
  height: 20vh;
}

.hp-70 {
  height: 70vh;
}

.main-container {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.submit-button {
  float: right;
  background: #2ec3e5;
  height: auto;
  font-size: 24px !important;
  color: white;
  border-radius: 5px;
  opacity: 1;
  font-weight: 300 !important;
  font-size: 22px;
  padding: 13px 0;
  width: 100% !important;
}

b {
  font-weight: bolder;
}

.inline-mail {
  color: #929292;
  text-decoration: none;
  font-size: 16px;
}

.inline-mail:hover {
  color: #505050;
}

@media (min-width: 0) {
  .vh-25 {
    height: 25vh !important;
  }

  .vh-30 {
    height: 30vh !important;
  }

  .vh-35 {
    height: 35vh !important;
  }

  .vh-40 {
    height: 42vh !important;
  }

  .vh-45 {
    height: 45vh !important;
  }

  .vh-50 {
    height: 50vh !important;
  }

  .container-element {
    height: 38vh !important;
  }

  .top-responsive {
    top: 14%;
  }

  .home-text {
    font-size: 20px !important;
    margin-left: 5px;
    font-weight: 300;
  }

  .logo-card {
    /*height: 30px !important;*/
  }

  .valoration-icon {
    width: fit-content;
  }

  .datalist-input input {
    height: 42px !important;
    min-height: 42px;
    border-radius: 5px;
    padding-left: 15px;
    border: none;
  }

  .css-yk16xz-control,
  .css-1s2u09g-control {
    height: 42px !important;
    min-height: 42px !important;
  }

  .dropdown-input {
    height: 55px !important;
  }

  .dropdown-input select {
    padding-left: 7px;
  }

  .show-less {
    position: absolute;
    top: 40%;
    left: 35%;
  }

  .show-more {
    position: absolute;
    top: 40%;
    right: 10px;
  }

  .checkbox-modal {
    margin-top: 20% !important;
  }

  .ReactModal__Content {
    height: fit-content;
    width: initial;
  }

  .movil-select {
    font-size: 16px;
  }

  .submit-button {
    width: 100%;
    border: none;
  }
}

@media (min-width: 768px) {
  .submit-button {
    padding: 8px 0 !important;
    font-size: 18px !important;
    max-width: 65%;
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 5rem !important;
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 5rem !important;
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 5rem !important;
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 5rem !important;
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }

  .container-element {
    height: 74vh !important;
  }

  .top-responsive {
    top: 30%;
  }

  .home-text {
    font-size: 1.4rem !important;
    line-height: 28px;
  }

  .logo-card {
    height: auto !important;
    max-height: 30px !important;
    width: auto;
  }

  .vh-40 {
    height: 72vh !important;
  }

  .gm-style .gm-style-iw-c {
    padding-top: 5px !important;
    width: 20vw;
  }

  .gm-style .gm-style-iw-c img {
    max-width: 100%;
    height: auto;
  }

  .ReactModal__Content {
    height: fit-content;
    width: auto;
  }

  .checkbox-modal {
    margin-top: 4px !important;
    margin-right: 10px !important;
  }

  .css-14el2xx-placeholder {
    font-size: 1rem;
  }

  .home-icons {
    width: 145px;
    height: 145px !important;
  }

  .text-last-center {
    text-align-last: right;
  }

  .submit-button {
    width: 250px;
  }

  .button-submit-resultados {
    width: 100%;
  }

  .dropdown-input {
    height: 45px !important;
  }

  .btn-comparador {
    font-size: 12px;
    position: absolute;
    margin-top: -12px;
    margin-left: 3px;
  }

  .inline-mail {
    display: block;
    color: #929292 !important;
  }
}

.datalist-input {
  height: 100%;
}

.data-list-input {
  height: 100%;
}

.exam-tooltip {
  width: max-content !important;
  background-color: #0a46a5;
  color: white;
  border-radius: 30px;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.button-submit-resultados {
  border: none;
  padding: 3px !important;
  line-height: 38px !important;
  height: 43px !important;
}

.bolder {
  font-weight: 900;
}

.ReactModal__Content {
  padding: 0 !important;
}

/**PR**/
.tab-comparador {
  border-radius: 0 !important;
  border: none !important;
  background-color: #2ec3e5 !important;
}

.css-1s2u09g-control {
  border-radius: 0;
  border: none;
  border-top: solid 1px #ccc;
}

.txt-contact {
  color: #0240a3;
}

.table-compare {
  font-size: 14px;
}

.table-compare .form-check-label {
  font-size: 12px !important;
}

.table-compare tr {
  border: none !important;
}

.table-compare th {
  padding: 2px 10px;
}

.table-compare td {
  border: solid 1px #ccc;
  padding: 0 5px;
}

.table-compare .form-check {
  padding-left: 0;
}

.table-compare .pay-button {
  text-align: center;
  font-size: 12px !important;
}

.table-compare p {
  margin: 0;
}

.table-compare .start {
  width: 12px !important;
  height: auto !important;
  margin-top: -1px;
}

.table-desktop tr {
  border: none !important;
}

.table-desktop td,
th {
  border: solid 1px #ccc !important;
}

.form-check .form-check-input {
  float: left;
  margin-left: 0 !important;
  margin-right: 5px;
}

footer {
  position: relative;
}

.whatsapp-fixed {
  z-index: 9999;
  position: fixed;
  right: 0;
  bottom: 30px;
}

.whatsapp-fixed span {
  max-width: 140px;
  display: inline-block;
  color: #4caf50;
  font-size: 14px;
  line-height: 16px;
}

.img-close-modal {
  width: 40px;
  cursor: pointer;
}

.form-check-label-card {
  margin-left: -33px;
}

.icon-compare {
  width: 18px;
  margin-top: -3px;
}

.start {
  width: 17px !important;
  margin: -4px 2px 0;
}

.card {
  border-radius: 8px !important;
}

.icon-card {
  width: 25px;
  margin-right: 5px;
}

.form-check-input:checked {
  background-color: #2ec3e5 !important;
  border-color: #2ec3e5 !important;
}

.form-check-input-blue:checked {
  background-color: #0a46a5 !important;
  border-color: #0a46a5 !important;
}

.image-fondo {
  background-image: url("./../assets/image/bannerprincipal.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 0;
}

@media (max-width: 768px) {
  .image-fondo {
    background-image: url("./../assets/image/banner-mobile.png");
  }
}

@media (min-width: 1336px) {
  .image-fondo {
    background-image: url("./../assets/image/bannerprincipal.png");
    background-size: cover;
    padding: 75px 0 0;
  }

  /*.filters-container {
    max-height: 26vh;
    overflow-y: scroll;
  }*/

  .scroll-card {
    height: 55vh;
  }

  .scroll-card::-webkit-scrollbar {
    width: 20px;
  }

  .scroll-card::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .scroll-card::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

@media (min-width: 1920px) {
  .image-fondo {
    padding: 130px 0 42px !important;
  }

  .scroll-card {
    height: 72vh;
  }
}

.text-green {
  color: #4caf50;
  font-size: 18px;
}

.b,
strong {
  font-weight: bold !important;
}

@media (max-width: 786px) {
  .vh-4 {
    height: 70vh !important;
  }

  .datalist-input input {
    height: 55px !important;
  }

  .css-yk16xz-control,
  .css-1s2u09g-control,
  .css-1s2u09g-control {
    height: 55px !important;
    min-height: 55px !important;
  }

  .css-1wa3eu0-placeholder,
  .css-14el2xx-placeholder,
  .css-14el2xx-placeholder {
    margin-left: 8px !important;
  }

  .movil-select .css-1s2u09g-control {
    height: 36px !important;
    min-height: 36px !important;
    border-radius: 0 !important;
    border: none;
    border-top: solid 1px #cccccc;
    border-bottom: solid 1px #cccccc;
  }
}

.compare-item-remove {
  float: right;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.votos {
  font-size: 12px;
  margin-left: 5px;
}

.pagination-control {
  display: flex;
  align-items: center;
  margin-left: 22px;
}

.pagination-control svg {
  cursor: pointer;
}

.pagination-control p {
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 13px;
}

.logo-remove {
  display: flex;
  min-height: 50px;
  line-height: 50px;
}

.logo-remove .logo {
  width: 100%;
  text-align: center;
}

.logo-remove .remove {
  width: 20px;
  position: relative;
}