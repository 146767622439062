.loading-element {
  height: 100%;
}

.container-element {
  width: 100%;
  float: right;
}

.map-element {
  height: 100%;
}
.gm-style-iw.gm-style-iw-c {
  max-width: 300px !important;
}
.gm-style img {
  max-width: 100%;
}
.info-window p {
  font-size: 12px;
}
.info-window .icon {
  width: auto;
  margin-right: 5px;
  height: 14px !important;
}
.info-window .total span {
  font-weight: bold;
  color: #0240a3;
}
.info-window .gray {
  color: #77838f;
}
